import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Creative UI/UX Design',
        description: 'Providing IT Solution worldwide and helping business to achieve thier technology goals.We are empowering business providing next generation software development solutions. We provide service of mobile application development, Unity game development , Web design & Development and Graphic Designs.'
    },
    {
        icon: <FiLayers />,
        title: 'Web Development',
        description: 'Website Design & Development, E-commerce & CMS, UX & UI Design, API & 3rd party Integrations, Responsive Design Support & Maintenance, Web & Social Apps. We create website according to your needs.'
    },
    {
        icon: <FiUsers />,
        title: 'Mobile Application Development',
        description: 'Development processes have evolved. In an agile environment we have hundreds of tools available to help us conceptualize our ideas. From a small draft on paper a prototype, all the way to the to end.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Machine Learning /AI',
        description: 'Our team of experts develops mobile and server machine learning solutions to benefit from the most innovative technological approaches.'
    },
    {
        icon: <FiUsers />,
        title: 'Dev Ops',
        description: 'DevOps is an essential part of any system. DevOps helps to plan the architecture for deployment of any product by which it can be available and accessible to the target audience.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Data visualization',
        description: 'Data visualization methods refer to the creation of graphical representations of information. Plays an important part of data analytics and helps interpret big data in a real-time structure by utilising complex sets of numerical or factual figures.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <h2 className="heading-title-h2" style={{textAlign: "center",lineHeight:"50px !important"}}> Our Services</h2>

                <div className="row">                                            
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#service">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
